ul.hover__slider {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  display: none;
}
ul.hover__slider:before {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  background: linear-gradient(to right, #021d34 0%, rgba(5, 51, 85, 0.5) 100%);
}

ul.hover__slider li {
  visibility: hidden;
  opacity: 0;
  transition: all 1s cubic-bezier(0.4, 0, 1, 1);
}

ul.hover__slider li.is-visible {
  visibility: visible;
  opacity: 1;
}

ul.hover__slider li img {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

@media (min-width: 768px) {
  ul.hover__slider {
    display: block;
  }
}
