.form__input {
  flex: 1 1 100%;
  height: 4.7rem;
  width: 100%;
  padding: 0 1.6rem;
  background-color: transparent;
  border: 0.1rem solid rgba($primary-dark-color, 0.25);
  box-shadow: none;
  border-radius: 2.35rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-appearance: none; /* to remove mobile safari boxshadow */
  font-size: 1.4rem;
  color: rgba($primary-dark-color, 0.85);
  background-color: #fff;
  font-weight: normal;
}
.form__input--noborder {
  height: 38px;
  border: 0;
}
.form__input:focus {
  border-color: rgba($primary-dark-color, 0.3);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.035),
    0 0 8px rgba($primary-dark-color, 0.2);
}
select.form__input {
  position: relative;
  z-index: 1;
  border-radius: 1.9rem;
  appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  padding-right: 5rem;
}
select.form__input:focus {
  outline: rgba(0, 0, 0, 0);
}
textarea.form__input {
  padding: 6px 10px;
  height: 34px;
  width: 100% !important;
  margin: 0 !important;
  display: flex;
}
.form__input[readonly] {
  color: #727272;
  background-color: #f5f5f5;
}
.select__wrapper {
  position: relative;
  flex: 1;
}
.select__wrapper:before {
  position: absolute;
  content: "";
  right: 2rem;
  top: 1.7rem;
  width: 0;
  height: 0;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  border-top: 0.6rem solid $color-3;
  z-index: 2;
  pointer-events: none;
}

.microcopy {
  margin-top: 0.7rem;
  padding-left: 1.6rem;
  font-size: 1.2rem;
  color: $color-4;
}

.microcopy--error {
  color: $error-color;
}

.input__group--error .form__input:focus {
  border-color: lighten($error-color, 20%);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.035),
    0 0 8px lighten($error-color, 15%);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba($primary-dark-color, 0.3);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba($primary-dark-color, 0.3);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba($primary-dark-color, 0.3);
}

@media (min-width: 768px) {
  .microcopy {
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
