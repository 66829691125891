ul.navigation {
  display: flex;
  align-items: center;
  font-size: calc(1.4rem + (16 - 14) * (100vw - 32rem) / (1920 - 320));
}

ul.navigation li ~ li {
  margin-left: 4rem;
}

ul.navigation li a {
  position: relative;
  padding-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.4);
}

ul.navigation li a:hover {
  color: #fff;
}

ul.navigation li a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.2rem;
  width: 100%;
  background-color: transparent;
  content: "";
  border-radius: 0.1rem;
}

ul.navigation li a.active {
  color: rgba(255, 255, 255, 1);
}

ul.navigation li a.active:before {
  background-color: rgba(255, 255, 255, 1);
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
