.promo {
  position: relative;
  margin-top: 7.2rem;
  padding: 7.2rem 3.2rem;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.next__article + .promo {
  margin-top: 0;
}

.promo:before {
  position: absolute;
  bottom: -100%;
  left:0;
  width: 100%;
  height: 200%;
  content: "";
  background: radial-gradient(#053355, #010614);
  z-index: 0;
}

.promo__item {
  position: relative;
  z-index: 1;
}

.promo h1 {
  margin: 0;
}

.promo h3 {
  margin: 3.2rem 0 6.4rem;
}

.promo__item .container {
  max-width: 90rem;
}