.context {
  color: $primary-dark-color;
}

.context:first-of-type {
  margin-top: 4.8rem;
}

.column__wrapper .context:first-of-type {
  margin-top: 0;
}

.section--about .context:first-of-type {
  margin-top: 2.4rem;
}

.context {
  padding: 4.8rem 0;
}

.context + .context {
  padding-top: 0;
  margin-top: 2.4rem;
}

.context h1 {
  margin-bottom: 3.2rem;
  font-size: calc(2.8rem + (46 - 28) * (100vw - 32rem) / (1920 - 320));
}

.context h2 {
  margin-bottom: 1.6rem;
  font-size: calc(2.1rem + (50 - 28) * (100vw - 32rem) / (1920 - 320));
}
.context h3 {
  margin-bottom: 1.6rem;
}

.context h4 {
  font-size: calc(1.6rem + (21 - 16) * (100vw - 32rem) / (1920 - 320));
}
.context h4 + p {
  margin-top: 1.6rem;
}

.context p {
  margin: 0;
  line-height: 1.9;
  color: rgba($primary-dark-color, 0.85);
}

.context p.muted {
  color: rgba($primary-dark-color, 0.4);
}

.context p.muted--info {
  font-size: calc(1.4rem + (16 - 14) * (100vw - 32rem) / (1920 - 320));
}

.context p + p,
.context p + h4 {
  margin-top: 3.2rem;
}

.context p + h3 {
  margin-top: 4.8rem;
}

.context a {
  color: $primary-color;
}

.context a:hover {
  color: darken($primary-color, 10%);
}

.context figure {
  width: 100%;
  margin: 4.8rem 0;
}

.context figure img {
  width: 100%;
}

.context figcaption {
  padding: 0 1rem;
  margin-top: 0.5rem;
  text-align: center;
  color: rgba($primary-dark-color, 0.6);
  font-size: calc(1.3rem + (16 - 13) * (100vw - 32rem) / (1920 - 320));
}

.context ul {
  margin: 3.2rem 0;
  padding-left: 2rem;
  list-style-type: initial;
}
.context ul li ~ li {
  margin-top: 1.6rem;
}

.context h3 + .image > .video__wrapper,
.context h3 + .image__wrapper,
.context h3 + .image > figure {
  margin-top: 3.2rem;
}

.image__wrapper {
  display: flex;
  flex-direction: column;
  margin: 4.8rem 0;
}

.image__wrapper .image {
  width: 100%;
  position: relative;
}

.image__wrapper .image figure {
  width: 100%;
  margin: 0;
}

.image__wrapper .image img {
  width: 100%;
}

.image__wrapper .image + .image,
.image__gallery__item + .image__gallery__item {
  margin-top: 4.8rem;
}

.image__wrapper + p {
  margin-top: 4.8rem;
}

span.order {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.6rem;
  left: 0.6rem;
  font-size: 1.3rem;
  height: 3rem;
  width: 3rem;
  background-color: rgba($primary-color, 0.7);
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  line-height: 0;
  letter-spacing: 0;
  border: 0.2rem solid rgba($primary-dark-color, 0.4);
}

.section__item--summary ul {
  margin: 0;
}

.section__item--summary ul + p {
  margin-top: 3.2rem;
}

.section__item--summary .context {
  margin: 0;
  padding: 9.6rem 0;
}

.image--full {
  line-height: 0;
  padding: 3.2rem;
  background-color: rgba(1, 6, 20, 0.025);
  border-top: 0.1rem solid rgba(1, 6, 20, 0.1);
  border-bottom: 0.1rem solid rgba(1, 6, 20, 0.1);
}
.image--full img {
  width: 100%;
}

.video__wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  display: block;
}

.youtube__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube__wrapper iframe {
  width: 100%;
  height: 100%;
  border: 0.2rem solid rgba(1, 6, 20, 0.2);
}

.aspect__ratio {
  padding-top: 56.25%;
}

.image--about {
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
}

.referral {
  margin-top: 4rem;
  line-height: 1.9;
  color: rgba(1, 6, 20, 0.85);
}

.referral span {
  font-size: 1.4rem;
  color: rgba($primary-dark-color, 0.4);
}

.referral p:first-of-type {
  margin: 0;
}

.referrer {
  display: flex;
  flex-direction: column;
  text-align: right;
}

@media (min-width: 768px) {
  .image__wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    width: 110%;
    margin: 6.4rem -5%;
  }
  .video__wrapper--full {
    width: 110%;
    max-width: 110%;
    margin: 6.4rem -5%;
  }
  .image__wrapper .image {
    flex: 0 0 calc(50% - 1.6rem);
  }
  .image__wrapper .image + .image {
    margin: 0 0 0 3.2rem;
  }
  .image__gallery__item .image + .image {
    margin: 4.8rem 0 0;
  }

  .image--about {
    margin-top: 3.2rem;
  }

  .referral {
    margin-top: 7rem;
  }
}
@media (min-width: 992px) {
  .context figure {
    width: 120%;
    margin: 6.4rem -10%;
  }
  .image__wrapper {
    width: 120%;
    margin: 6.4rem -10%;
  }
  .video__wrapper--full {
    width: 120%;
    max-width: 120%;
    margin: 6.4rem -10%;
  }
  .image__wrapper--gallery {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .image__wrapper--gallery figcaption {
    width: 100%;
  }
  .image__gallery__item + .image__gallery__item {
    margin-top: 0;
  }

  .image__gallery__item--left {
    flex: 0 0 calc(76% - 1.2rem);
    margin-right: 1.2rem;
  }
  .image__gallery__item--right {
    flex: 0 0 calc(23.2% - 1.2rem);
    margin-left: 1.2rem;
  }
  .image__gallery__item .image + .image {
    margin: 1.6rem 0 0;
  }
}
@media (min-width: 1200px) {
}
