.header {
  background: linear-gradient(to right, #010614, #053355 100%);
}

.header .container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10.5rem;
  padding: 0 1.6rem 0.5rem;
}

.header__item .logo a {
  color: $primary-color;
}

.header__item + .header__item {
  margin-top: 1.6rem;
}

.logo {
  color: #fff;
  font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-weight: bold;
  font-size: 2.8rem;
}

@media (min-width: 768px) {
  .header .container {
    flex-direction: row;
    justify-content: space-between;
    max-width: 120rem;
    height: 12rem;
    padding: 0 2rem;
  }

  .header__item + .header__item {
    margin-top: 0;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
