.about__item--header {
  padding: 4.8rem 0;
  background: linear-gradient(135deg, #010614, #053355 100%);
}

.about__item--content {
  padding: 4.8rem 0;
  color: $primary-dark-color;
  line-height: 1.8;
}

.about__item--content .column {
  flex: 0 0 100%;
}

.about__item--header h1 {
  font-size: 2.1rem;
  margin-bottom: 0.5rem;
}
.about__item--header h4 {
  color: rgba(255,255,255,0.7);
  font-size: 1.4rem;
}

.about__item--content h3 {
  font-size: 2.1rem;
  margin-bottom: 3.2rem;
}

.about__item--content p {
  font-size: 1.6rem;
  margin: 2.4rem 0;
  color: rgba($primary-dark-color, 0.85);
}

.about__item--content a {
  font-weight: bold;
  color: $primary-color;
  transition: all 1s;
}

.about__item--content a:hover {
  color: darken($primary-color, 10%);
}

.about--header .container {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10.5rem;
  padding: 0 1.6rem 0.5rem;
}

.column__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 120rem;
}

.column {
}
.column--left {
  flex: 0 0 70%;
  padding-right: 1.6rem;
}
.column--right {
  flex: 1;
  padding-left: 1.6rem;
  height: 100%;
}

.profile__picture {
  position: relative;
  height: 100%;
  width: 100%;
}

.profile__picture img {
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
}

@media (min-width: 768px) {
  .about__item--header {
    display: flex;
    height: 22rem;
  }

  .about--header .container {
    flex-direction: row;
    justify-content: space-between;
    max-width: 120rem;
    height: 12rem;
    padding: 0 2rem;
  }

  .profile__picture img {
    position: absolute;
    padding-bottom: 100%;
  }
  .about__item--header h1 {
    font-size: 3.7rem;
  }
  .about__item--header h4 {
    font-size: 1.8rem;
  }

  .column--left,
  .about__item--content .column--left {
    flex: 1;
  }
  .column--right,
  .about__item--content .column--right {
    flex: 0 0 36rem;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

// .column__wrapper {
//   display: flex;
//   align-items: center;
//   width: 100%;
//   max-width: 120rem;
// }

// .column {
//   display: flex;
// }
// .column--left {
//   flex-direction: column;
//   flex: 0 0 75%;
// }
// .column--right {
//   flex: 1;
// }

// .profile__picture {
//   position: relative;
//   width: 100%;
// }

// .profile__picture img {
//   width: 100%;
//   height: 100%;
//   border-radius: 0.4rem;
// }

// @media (min-width: 768px) {
//   .column__wrapper {
//     align-items: flex-start;
//     height: 100%;
//     padding: 0 2rem;
//   }
//   .column--left {
//     display: flex;
//     justify-content: center;
//     flex: 1;
//     height: 100%;
//     padding-right: 1.6rem;
//   }

//   .column--right {
//     flex: 0 0 39rem;
//     padding-left: 1.6rem;
//   }
//   .column--left h1 {
//     font-size: 4.1rem;
//   }

//   .column--left h4 {
//     font-size: 1.6rem;
//   }
//   .profile__picture img {
//     position: absolute;
//     top: 0;
//     right: 0;
//     padding-bottom: 100%;
//   }
// }
