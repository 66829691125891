.section {
  flex: 1;
  background-color: #fff;
}

.section__item--header {
  min-height: 2rem;
}

.section--portfolio .section__item--header h1 {
  margin-bottom: 3.2rem;
  font-size: calc(2.8rem + (67 - 28) * (100vw - 32rem) / (1920 - 320));
}
.section--portfolio .section__item--header p {
  margin: 2.4rem 0;
}

.section--portfolio {
  color: $primary-dark-color;
  font-size: calc(1.6rem + (21 - 16) * (100vw - 32rem) / (1920 - 320));
}

.section__item--summary {
  background-color: rgba($primary-dark-color, 0.025);
}

.section__item--warning {
  background-color: rgb(251, 241, 181);
}

.section--portfolio .section__item--header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.8rem 1.6rem;
  color: #fff;
  background: linear-gradient(135deg, #010614, #053355 100%);
  text-align: center;
}

.section--contact .section__item--header {
  display: flex;
  padding: 4.8rem 1.6rem;
}

.section--contact .section__item--header h3 {
  margin: 0.5rem 0 0;
  color: rgba($primary-dark-color, 0.6);
}
.section--contact .section__item--header p {
  margin: 2.4rem 0 0;
  color: rgba($primary-dark-color, 0.6);
  font-size: 1.6rem;
}

.section__item--header .container {
  padding: 0;
}

.section--contact .container {
  padding: 0 1.6rem;
}

@media (min-width: 768px) {
  .section--portfolio .section__item--header {
    padding: 9.6rem 3.2rem;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}

@media (min-height: 650px) {
  .section--portfolio .section__item--header {
    height: calc(100vh - 14rem);
    max-height: 60rem;
  }
}
