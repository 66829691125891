h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font-family;
  line-height: 1.3;
}

.content h1 {
  margin-bottom: 4rem;
}

.content a.inline {
  color: rgba($primary-color, 0.6);
  transition: color 3s cubic-bezier(0, 0, 0.2, 1);
}
.content a.inline:hover {
  color: $primary-color;
}

.content p {
  margin: 3.2rem 0;
  font-size: calc(1.6rem + (26 - 16) * (100vw - 32rem) / (1920 - 320));
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.5;
}

