ul.skillset {
  display: flex;
  flex-direction: column;
  margin: 2.4rem 0 0;
  padding-top: 4.8rem;
  border-top: 2px solid rgba(255, 255, 255, 0.03);
  border-radius: 0.1rem;
}
ul.skillset li {
  padding: 0 0.4rem;
  border-bottom: 0.5rem solid transparent;
  font-size: calc(1.4rem + (16 - 14) * (100vw - 32rem) / (1920 - 320));
  color: rgba(255, 255, 255, 0.3);
}

ul.skillset li ~ li {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  ul.skillset {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  ul.skillset li ~ li {
    margin: 0 0 0 3.2rem;
  }
}
