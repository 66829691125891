.next__article {
  position: relative;
  margin-top: 8rem;
}

.next__article a {
  position: relative;
  display: flex;
  padding: 6.4rem 3.2rem;
  overflow: hidden;
  background-color: $primary-dark-color;
  transition: background-color 3s cubic-bezier(0.25, 0.25, 0, 1);
  z-index: 10;
}

.next__article:hover a {
  background-color: rgba($primary-dark-color, 0.85);
}


.next__article img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  z-index: 5;
}

.next__article h1 {
  color: rgba(255, 255, 255, 0.9);
  transition: color 3s cubic-bezier(0.25, 0.25, 0, 1);
}
.next__article:hover h1 {
  color: $primary-color;
}

.next__article h4 {
  color: rgba(255, 255, 255, 0.3);
}

.next__article p {
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
  .next__article a {
    padding: 9.6rem 3.2rem;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
