.image__zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
  opacity: 0;
  visibility: hidden;
  transition: 260ms all cubic-bezier(0.4, 0, 1, 1);
}
.image__zoom:before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    135deg,
    rgba(1, 6, 20, 1),
    rgba(5, 51, 85, 0.9) 100%
  );
  content: "";
}

.zoom__image__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.8rem;
  height: 2.8rem;
  border: 0.15rem solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.5);
  content: "x";
  z-index: 10;
  text-align: center;
  line-height: 1.3;
  font-weight: bold;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.6rem;
}

.zoom__image__close:hover {
  border-color: rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
}

.image__zoom--is-active {
  opacity: 1;
  visibility: visible;
  cursor: zoom-out;
  z-index: 100;
}

.image__zoom img {
  width: 100% !important;
  height: initial !important;
  transform: scale(0.95);
  transform-origin: center;
  transition: 320ms all cubic-bezier(0.4, 0, 1, 1);
}

.image__zoom--is-active img {
  transform: scale(1);
}

figure {
  width: 100%;
  margin: 4.8rem 0;
}

figure img {
  width: 100%;
  border: 0.2rem solid rgba($primary-dark-color, 0.2);
  border-radius: 0.4rem;
}

figure.no-border img {
  border: 0;
}

figcaption {
  padding: 0 1rem;
  margin-top: 1rem;
  text-align: center;
  color: rgba($primary-dark-color, 0.5);
  font-size: calc(1.3rem + (16 - 13) * (100vw - 32rem) / (1920 - 320));
}

.image__cursor {
  cursor: zoom-in;
}

@media (min-width: 768px) {
  // .image__zoom img {
  //   width: initial !important;
  //   height: 85% !important;
  // }
}
@media (min-width: 992px) {
  figure {
    width: 120%;
    margin: 6.4rem -10%;
  }
  .image__zoom {
    padding: 5% 10%;
  }
  .zoom__image__close {
    top: 2rem;
    right: 2rem;
  }
}
@media (min-width: 1200px) {
}

@media (max-height: 400px) and (max-width: 1200px) {
  .image__zoom {
    padding: 2rem;
  }
  .image__zoom img {
    height: 100% !important;
    width: initial !important;
  }
}
