.section--contact {
  color: $primary-dark-color;
}

ul.contact__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4.8rem 0 0;
}
ul.contact__info li ~ li {
  margin-top: 2.4rem;
}
ul.contact__info li a {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.contact__info li .contact__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6rem;
  width: 6rem;
  margin-right: 1.6rem;
  background: radial-gradient(#053355, #010614);
  border-radius: 50%;
}

ul.contact__info li .contact__icon img {
  width: 5rem;
  height: 5rem;
  opacity: 0.85;
}

ul.contact__info li span {
  font-size: 1.6rem;
  color: rgba($primary-color, 1);
  margin: 0;
  // font-weight: bold;
}

@media (min-width: 768px) {}
@media (min-width: 992px) {}
@media (min-width: 1200px) {}
