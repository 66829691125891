button:focus,
input[type="submit"] {
  outline: 0;
}

.btn,
a.btn {
  display: inline-block;
  padding: 1.1rem 1.8rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 0.2rem solid transparent;
  font-size: calc(1.4rem + (16 - 14) * (100vw - 32rem) / (1920 - 320));
  line-height: 1.5;
  border-radius: 2.4rem;
  white-space: nowrap;
  cursor: pointer;
  transition: color 1s cubic-bezier(0.4, 0, 1, 1),
    background-color 1s cubic-bezier(0.4, 0, 1, 1),
    border-color 1s cubic-bezier(0.4, 0, 1, 1),
    box-shadow 1s cubic-bezier(0.4, 0, 1, 1);
  font-family: "Lato", "Arial", sans-serif;
  letter-spacing: 0.13em;
}

.btn i {
  font-size: 1.6rem;
}
.btn--primary,
a.btn--primary {
  color: #fff;
  border-color: $primary-color;
  background-color: transparent;
  @include hover {
    background-color: $primary-color;
    border-color: transparent;
    color: #fff;
  }
}

.btn--link {
  padding: 0;
  border-radius: 0;
  color: rgba($primary-color, 0.5);
  border-color: transparent;
  background-color: transparent;
  @include hover {
    border-bottom-color: $primary-color;
    color: $primary-color;
  }
}

.btn--login {
  color: $primary-dark-color;
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
