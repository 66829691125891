body {
  font-family: $content-font-family;
  font-weight: 400;
  color: $color-1;
  line-height: 1.4;
  font-size: calc(1.8rem + (32 - 18) * (100vw - 32rem) / (1920 - 320));
}

html,
body,
#app,
.wrapper {
  height: 100%;
  min-height: 100%;
}
.wrapper {
  width: 100%;
}

.wrapper--auth {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}
.wrapper:before {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(to right, #010614, #053355 100%);
  content: "";
}

.wrapper--auth:before {
  background: none;
}

.container {
  display: flex;
  flex: 1;
  max-width: 90rem;
  margin: 0 auto;
  padding: 0 1.6rem;
}

.container--normal {
  display: block;
}

.container--column {
  flex-direction: column;
}

.section--portfolio .container {
  max-width: 70rem;
}


@keyframes bottom-to-top {
  from {
    transform: translate3d(0, 50%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
