.persona {
  display: flex;
  flex-direction: column;
  margin: 4.8rem 0;
}

.persona__item {
  flex: 1;
}
.persona__item + .persona__item {
  margin-top: 3.2rem;
}
.persona__item--image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.persona__item--image img {
  padding: 1rem;
  max-width: 20rem;
  border-radius: 50%;
  background: linear-gradient(
    to top,
    rgba(1, 6, 20, 0.5),
    rgba(5, 51, 85, 0.9) 100%
  );
}

.persona__name {
  position: absolute;
  width: 100%;
  max-width: 12rem;
  padding: 0.8rem 2.4rem;
  bottom: 0;
  background: linear-gradient(to right, #010614, #053355 100%);
  border-radius: $border-radius;
  border: 0.2rem solid rgba(255, 255, 255, 1);
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.08rem;
  text-align: center;
}

.persona__item ul {
  margin: 2.4rem 0 0;
  color: rgba(1, 6, 20, 0.8);
}

@media (min-width: 768px) {
  .persona {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .persona__name {
    margin-bottom: 4.8rem;
  }
  .persona__item {
    flex: 0 0 calc(50% - 0.1rem);
    padding: 4.8rem;
  }
  .persona__item + .persona__item {
    margin-top: 0rem;
  }
  .persona__item:nth-child(2n) {
    border-left: 1px solid rgba(117, 121, 130, 0.5);
  }
  .persona__item:nth-child(2n-1) {
    border-right: 1px solid rgba(117, 121, 130, 0.5);
  }
  .persona__item:nth-child(1) {
    border-bottom: 0.1rem solid rgba(117, 121, 130, 0.5);
  }
  .persona__item:nth-child(2) {
    border-bottom: 0.1rem solid rgba(117, 121, 130, 0.5);
  }
  .persona__item:nth-child(3) {
    border-top: 0.1rem solid rgba(117, 121, 130, 0.5);
  }
  .persona__item:nth-child(4) {
    border-top: 0.1rem solid rgba(117, 121, 130, 0.5);
  }
}
@media (min-width: 992px) {
  .persona {
    width: 120%;
    margin: 6.4rem -10%;
  }
}
@media (min-width: 1200px) {
}
